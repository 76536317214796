@font-face {
  font-family: 'CircularXXT-Black';
  src: url(../_assets/fonts/CircularXXTT-Black.ttf);
}
@font-face {
  font-family: 'CircularXXT-BlackItalic';
  src: url(../_assets/fonts/CircularXXTT-BlackItalic.ttf);
}
@font-face {
  font-family: 'CircularXXT-Bold';
  src: url(../_assets/fonts/CircularXXTT-Bold.ttf);
}
@font-face {
  font-family: 'CircularXXT-BoldItalic';
  src: url(../_assets/fonts/CircularXXTT-BoldItalic.ttf);
}
@font-face {
  font-family: 'CircularXXTT-Book';
  src: url(../_assets/fonts/CircularXXTT-Book.ttf);
}
@font-face {
  font-family: 'CircularXXTT-BookItalic';
  src: url(../_assets/fonts/CircularXXTT-BookItalic.ttf);
}
@font-face {
  font-family: 'CircularXXTT-Medium';
  src: url(../_assets/fonts/CircularXXTT-Medium.ttf);
}
@font-face {
  font-family: 'CircularXXTT-MediumItalic';
  src: url(../_assets/fonts/CircularXXTT-MediumItalic.ttf);
}
