$primary-color: #0069b4;
$text-color: #181616;
$placeholder-color: #b8bcbf;
$disabled-background-color: #f3f3f3;
$error-background-color: #fff6f6;
$error-border-color: hsl(0, 42%, 79%);
$error-text-color: #9f3a38;
$error-placeholder-color: #e7bdbc;
$input-border-color: rgba(34, 36, 38, 0.25);

$shadow: rgba(34, 36, 38, 0.15);
$white-transparent: rgba(255, 255, 255, 0.2);

$grey: #999fa3;
$grey-dark: #3a3a3a;
$grey-light: #eeeeee;
$grey-lighter: #f9fafb;
$grey-blue: #f1f5f9;
$red: #e45f55;
$red-light: #fceaea;
$green: #24c289;
$orange: #ea560d;
$orange-light: #fdefe8;
$white: #ffffff;
$black: #000000;
$blue: #0069b4;
$blue-light: #e0edf6;

@function darkenOnHover($color, $percentage: 15%) {
  @return darken($color, $percentage);
}
