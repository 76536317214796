@import '../../_styles/colors';

$size-normal: 1.8rem;
$size-large: 2.5rem;

@keyframes spinner-solid-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-wrapper {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .spinner {
    position: relative;

    &.size-normal {
      width: $size-normal;
      height: $size-normal;
    }
    &.size-large {
      width: $size-large;
      height: $size-large;
    }

    > div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 2px solid;
      border-radius: 50%;
      animation: spinner-solid-circle 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  &.theme-normal {
    color: $primary-color;

    .spinner > div {
      border-color: $primary-color transparent transparent transparent;
    }

    p {
      color: $grey-dark;
    }
  }

  &.theme-inversed {
    color: $white;
    .spinner > div {
      border-color: $white transparent transparent transparent;
    }
  }

  .spinner-content {
    margin-top: 1rem;
  }
}
