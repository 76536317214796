@import '../../_styles/layout';

.feeder-detail {
  @include layout;

  h2 {
    font-size: 1.6rem;
    opacity: 0.6;
    margin-bottom: 4rem;
  }
}
