@import './breakpoints';
@import './constants';

@mixin layout {
  margin: $spacingMargin;
  max-width: 80%;

  @include breakpoint('tablet') {
    max-width: 95%;
  }
}
