@import '../../_styles/colors';

a.go-back {
  padding-left: 0;
  font-weight: normal;

  span {
    color: $grey-dark;
  }

  &.as-text {
    color: transparent;

    &:hover {
      color: transparent;
    }
  }
}
