@import '../../_styles/colors';

.header-bar {
  background-color: $primary-color;
  width: 100%;
  height: 7.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem;
  position: relative;

  > .icon {
    cursor: pointer;
  }

  .language-dialog {
    background-color: $white;
    padding: 2.4rem;
    position: absolute;
    top: 6rem;
    box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    font-family: CircularXXTT-Book;
    z-index: 1;
    min-width: 37rem;

    h3 {
      font-size: 1.8rem;
      color: $black;
      opacity: 0.6;
      letter-spacing: 0.234px;
      font-weight: normal;
    }

    &__item {
      padding: 1.2rem 0;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:last-of-type) {
        border-bottom: 1px solid $grey-light;
      }

      &:first-of-type {
        margin-top: 2.4rem;
      }

      p {
        color: $grey-dark;
      }

      .icon {
        color: $blue;
      }
    }
  }
}
