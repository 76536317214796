@import '../../_styles/breakpoints';
@import '../../_styles/colors';
@import '../../_styles/layout';

$padding: 0 1.6rem;

.feeder-search {
  @include layout;

  &__header {
    h1 {
      color: $primary-color;
      font-size: 1.8rem;
    }

    .search-field-wrapper {
      margin-top: 1.2rem;
    }

    hr {
      margin-top: 1.2rem;
      border: 1px solid $grey-light;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 50rem;

    > p {
      margin-top: 0.8rem;
      max-width: 33rem;
      text-align: center;
      color: $grey-dark;
    }

    .spinner-wrapper {
      margin-top: 2rem;
    }

    .previous-searches {
      margin-top: 3.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      h5 {
        font-size: 1.2rem;
        color: $black;
        opacity: 0.6;
      }

      &__wrapper {
        display: flex;
        margin-top: 1.2rem;

        &__item {
          background-color: $blue-light;
          color: $blue;
          padding: 0.4rem 0.8rem;
          width: fit-content;
          font-weight: bold;
          cursor: pointer;

          &:not(:last-of-type) {
            margin-right: 1.2rem;
          }
        }
      }
    }

    &__empty-view {
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      > .icon {
        color: $white;
      }
    }
  }
}
