@import '../../../_styles/colors';

.feeder-item {
  height: 5.8rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.4rem 0 1.2rem;
  cursor: pointer;

  &:hover {
    background-color: $grey-blue;
  }

  p {
    font-weight: bold;
  }

  .icon {
    color: $primary-color;
  }
}
