@import './colors';

form {
  display: flex;
  flex-direction: column;

  > :not(:first-child) {
    margin-top: 2rem;
  }

  div[role='group'] {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    > :not(:first-child) {
      margin-left: 2rem;
    }

    > * {
      flex: 1;
    }
  }
}

.actions {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > :not(:first-child) {
    margin-left: 1rem;
  }
}
