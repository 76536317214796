main {
  h1 {
    margin-bottom: 2rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .header-group {
      display: flex;

      > :not(:first-child) {
        margin-left: 1.5rem;
      }
    }
  }

  .table {
    margin-top: 1.5rem;
  }
}
