@import '../../_styles/colors';

.search-field-wrapper {
  position: relative;

  .input-wrapper {
    .input {
      padding-right: 3.5rem;
      background-color: $grey-blue;
      border: none;
      border-radius: 4px;
    }
  }
}

.search-field-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: $grey-dark;
  outline: none;
}
