@import '../../../_styles/constants';
@import '../../../_styles/colors';

.fetcher-item {
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
  justify-content: space-between;

  &:not(:first-of-type) {
    margin-top: 2rem;
  }

  span {
    font-weight: bold;
  }

  &__count {
    width: 4rem;
    height: 4.8rem;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $blue;
    background-color: $blue-light;

    &.has-many-fetchers {
      color: $orange;
      background-color: $orange-light;
    }
  }
}
